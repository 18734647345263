import * as React from 'react';
import mixpanel from 'mixpanel-browser';
import './src/styles/global.css';

import { MixpanelContext } from './src/tracking';
const token = process.env.GATSBY_MIXPANEL_TOKEN || '';
export const wrapRootElement = ({ element }) => {

  mixpanel.init(token, { debug: false, track_pageview: true, persistence: 'localStorage', ignore_dnt: true });
  if (process.env.NODE_ENV !== 'production') {
    mixpanel.disable();
  }

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  );
};